import React, { useState, createContext, Dispatch, SetStateAction } from 'react';

interface I_ThemeProvider {
    children: React.ReactNode;
}

interface I_ThemeContext {
    isDark: boolean;
    changeTheme: (isDark: boolean) => void;
}

const initialState = {
    isDark: true,
    changeTheme: () => {}
};

export const ThemeContext = createContext<I_ThemeContext>(initialState);

export const ThemeProvider = ({ children }: I_ThemeProvider) => {
    const [isDark, setTheme] = useState<boolean>(true);

    return (
        <ThemeContext.Provider
            value={{
                isDark,
                changeTheme: isDark => {
                    setTheme(isDark);
                }
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};
